import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';
import Tab from 'react-bootstrap/Tab'
import Line from '../../components/SimpleElements/line';
import ChartsGeneralJitsi from './Charts/ChartsGeneralJitsi';
import ChartsGeneralZulip from './Charts/ChartsGeneralZulip';
import moment from 'moment'
import ChartsGeneralProject from './Charts/ChartsGeneralProject';
import ProjectNavbar from './Navbar/ProjectNavbar';

var d = {}
for (var i in window.location.search.replace('?', '').split('&')) {
	d[window.location.search.replace('?', '').split('&')[i].split('=')[0]] = window.location.search.replace('?', '').split('&')[i].split('=')[1]
}
let email = d['slug'] + '@miem.hse.ru'

class ElementsGeneralStats extends React.Component {

	constructor() {
		super();
		let end = moment();
		this.last_year = moment().subtract(1, 'years');
		this.state = {data: [email, this.last_year.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]};
		this.changeData = this.changeData.bind(this);
	}

	//Получение данных от DataPicker
	changeData(start, end) {
		let begin_format = start.format('YYYY-MM-DD')
		let end_format = end.format('YYYY-MM-DD')
		this.setState({
				data: [email, begin_format, end_format]
			}
		);
	}

	render() {
		return (
			<Tab.Container id="left-tabs-example" defaultActiveKey="generaljitsi_pill">
				<Navbar/>
				<Line/>
				<Tab.Content>
					<Tab.Pane eventKey="generaljitsi_pill">
						<ChartsGeneralJitsi data={this.state.data}/>
					</Tab.Pane>
					<Tab.Pane eventKey="classroom_pill">
						<ChartsGeneralZulip data={this.state.data}/>
					</Tab.Pane>
					<Tab.Pane eventKey="project_pill">
						<Tab.Container defaultActiveKey="zulip">
							<ProjectNavbar/>
							<Tab.Content>
								<Tab.Pane eventKey="zulip">
									<ChartsGeneralProject type={'Zulip'}/>
								</Tab.Pane>
								<Tab.Pane eventKey="taiga">
									<ChartsGeneralProject type={'Taiga'}/>
								</Tab.Pane>
								<Tab.Pane eventKey="wekan">
									<ChartsGeneralProject type={'Wekan'}/>
								</Tab.Pane>
								<Tab.Pane eventKey="gitlab">
									<ChartsGeneralProject type={'Gitlab'}/>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		);
	}
}

export default ElementsGeneralStats