import React from 'react'
import CoursesUnit from './CoursesUnit'
import coursera from './images/coursera.jpg'
import stepik from './images/stepik.jpg'
import openedu from './images/openedu.png'

function CoursesRows(props) {
  let courses = []
  for (let i = 0; i < props.courses.length; i++) {
    const url = props.courses[i].url
    const domain = url.replace(/.+\/\/|www.|\..+/g, '')
    let img = null
    switch (domain) {
      case 'coursera':
        img = coursera
        break
      case 'stepik':
        img = stepik
        break
      case 'openedu':
        img = openedu
        break
      default:
        break
    }
    const author =
      props.courses[i].author === 'nan' ? null : props.courses[i].author
      
    courses.push(
      <CoursesUnit
        i={i + 1}
        name={props.courses[i].title}
        img={img}
        author={author}
        rating={props.courses[i].score}
        number={props.courses[i].num_students}
        href={url}
        key={i + 1}
      />
    )
  }

  return <div>{courses}</div>
}

export default CoursesRows
