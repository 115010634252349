import React from 'react'
import Highcharts from 'highcharts/highstock';
import getGeneralData_zulip from '../../../services/api/FetchGeneralZulip';
import getTop_zulip from '../../../services/api/FetchTopGeneralZulip';
import DatatableZulipPage from '../../../components/Tables/Table'
import drilldown from 'highcharts/modules/drilldown'
import Spinner from 'react-bootstrap/Spinner'
import '../../../components/SimpleElements/style.css'

drilldown(Highcharts);

class ChartsGeneralZulip extends React.Component {

	constructor() {
		super();
		this.state = {data_table: {}, showSpinner: true}
	}

	//Отрисовка данных по общей статистике Zulip
	print_general_zulip() {
		getGeneralData_zulip((data) => {
			if (data['mentions'] === undefined || data.hasOwnProperty('error')) { // Нет данных
				document.getElementById('container').innerHTML = '<div class="noData"> <div class="noData_inner"> Нет данных </div></div>'
				console.log('Нет данных')
				return
			} else {
				this.setState({data_table: {}})
				var year = ''
				var type = ''

				let options = {
					messages: [],
					mentions: [],
					data_month: []
				};

				var data_month = {}
				for (let i = 0; i < data["mentions"].length; i++) {
					if (data['mentions'][i]['month'] === '*') {
						options.mentions.push({
							'name': data['mentions'][i]['year'],
							'y': data['mentions'][i]['mentions'],
							'drilldown': `${data['mentions'][i]['year']}-mentions`
						})
					} else {
						if (!data_month[data['mentions'][i]['year']]) {
							data_month[data['mentions'][i]['year']] = {
								'name': data['mentions'][i]['year'],
								'id': `${data['mentions'][i]['year']}-mentions`,
								'data': []
							}
						}
						data_month[data['mentions'][i]['year']]['data'].push([
							getDate(data['mentions'][i]['month']), data['mentions'][i]['mentions']
						])
					}
				}
				for (let key in data_month) {
					options.data_month.push(data_month[key])
				}

				data_month = {}
				for (let i = 0; i < data["messages"].length; i++) {
					if (data['messages'][i]['month'] === '*') {
						options.messages.push({
							'name': data['messages'][i]['year'],
							'y': data['messages'][i]['messages'],
							'drilldown': `${data['mentions'][i]['year']}-messages`
						})
					} else {
						if (!data_month[data['messages'][i]['year']]) {
							data_month[data['messages'][i]['year']] = {
								'name': data['messages'][i]['year'],
								'id': `${data['messages'][i]['year']}-messages`,
								'data': []
							}
						}
						data_month[data['messages'][i]['year']]['data'].push([
							getDate(data['messages'][i]['month']), data['messages'][i]['messages']
						])
					}
				}

				for (let key in data_month) {
					options.data_month.push(data_month[key])
				}
				data_month = {}

				//console.log(options.messages)
				//console.log(options.mentions)
				//console.log(options.data_month)

				this.setState({showSpinner: false})

				//Установка названия кнопки назад
				Highcharts.setOptions({
					lang: {
						drillUpText: '<< Назад'
					}
				});
				Highcharts.chart('container', {
					chart: {
						type: 'column',
						events: {
							drillup: (event) => {
								this.setState({data_table: {}})
							}
						}
					},
					title: {
						text: 'Общая статистика по сервису Zulip'
					},
					subtitle: {
						text: 'Для получения более подробной информации нажмите на столбец диаграммы и прокрутите вниз'
					},
					accessibility: {
						announceNewData: {
							enabled: true
						}
					},
					xAxis: {
						type: 'category'
					},
					yAxis: {
						title: {
							text: 'Количеcтво'
						}

					},
					legend: {
						enabled: false
					},
					tooltip: {
						headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
						pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b><br/>'
					},

					series: [{
						name: 'Сообщения',
						data: options.messages
					},
						{
							name: 'Упоминания',
							data: options.mentions

						}],
					drilldown: {
						breadcrumbs: {
							position: {
								align: 'right'
							}
						},
						activeAxisLabelStyle: {
							textDecoration: 'none',
							color: 'black',
							pointerEvents: 'none'
						},
						activeDataLabelStyle: {
							textDecoration: 'none',
							color: 'black',
							pointerEvents: 'none'
						},
						series: options.data_month
					},
					plotOptions: {
						series: {
							events: {
								click: (event) => {
									if (parseInt(event.point.name)) { // Топ пользователей по году
										year = event.point.name
										//console.log("Год")
										//console.log(event.point.name)
										if (event.point['colorIndex'] === 0) {
											getTop_zulip('messages', year, 'null', (data_top) => {
												if (data.hasOwnProperty('error')) {
													console.log('error')
												} else {
													type = 'messages'
													data_top = Array.from(data_top.entries()).map((el) => {
														el[1]['rank'] = el[0] + 1
														return el[1]
													})
													this.setState({
														data_table: {
															columns: [
																{
																	label: 'Место',
																	field: 'rank',
																	sort: 'asc',
																	width: 100
																},
																{
																	label: 'Пользователь',
																	field: 'name',
																	sort: 'asc',
																	width: 300
																},
																{
																	label: 'Почта пользователя',
																	field: 'email_google',
																	sort: 'asc',
																	width: 300
																},
																{
																	label: 'Количество сообщений',
																	field: 'messages',
																	sort: 'asc',
																	width: 270
																}
															],
															rows: data_top,
															title: `Топ ${data_top.length} пользователей по сообщениям за ` + year + ' год'
														}
													});
												}
											});
										} else {
											getTop_zulip('mentions', year, 'null', (data_top) => {
												if (data.hasOwnProperty('error')) {
													console.log('error')
												} else {
													type = 'mentions'
													data_top = Array.from(data_top.entries()).map((el) => {
														el[1]['rank'] = el[0] + 1
														return el[1]
													})
													this.setState({
														data_table: {
															columns: [
																{
																	label: 'Место',
																	field: 'rank',
																	sort: 'asc',
																	width: 100
																},
																{
																	label: 'Пользователь',
																	field: 'name',
																	sort: 'asc',
																	width: 300
																},
																{
																	label: 'Почта пользователя',
																	field: 'email_google',
																	sort: 'asc',
																	width: 300
																},
																{
																	label: 'Количество упоминаний',
																	field: 'mentions',
																	sort: 'asc',
																	width: 270
																}
															],
															rows: data_top,
															title: `Топ ${data_top.length} пользователей по упоминаниям за ` + year + ' год'
														}
													});
												}
											});
										}
									} else { // Топ пользователей по месяцам за выбранный год
										//console.log("Месяц")
										//console.log(year)
										//console.log(event.point.name)
										//console.log(event.point.name)
										let month = getNumberDate(event.point.name)
										if (type === 'messages') { // Топ пользователей по сообщениям
											getTop_zulip('messages', year, month, (data_top) => {
												if (data.hasOwnProperty('error')) {
													console.log('error')
												} else {
													data_top = Array.from(data_top.entries()).map((el) => {
														el[1]['rank'] = el[0] + 1
														return el[1]
													})
													this.setState({
														data_table: {
															columns: [
																{
																	label: 'Место',
																	field: 'rank',
																	sort: 'asc',
																	width: 100
																},
																{
																	label: 'Пользователь',
																	field: 'name',
																	sort: 'asc',
																	width: 300
																},
																{
																	label: 'Почта пользователя',
																	field: 'email_google',
																	sort: 'asc',
																	width: 300
																},
																{
																	label: 'Количество сообщений',
																	field: 'messages',
																	sort: 'asc',
																	width: 270
																}
															],
															rows: data_top,
															title: `Топ ${data_top.length} пользователей по сообщениям за ` + getRusDate(event.point.name) + ' ' + year + ' года'
														}
													});
												}
											});
										} else { // Топ пользователей по упоминаниям
											getTop_zulip('mentions', year, month, (data_top) => {
												if (data.hasOwnProperty('error')) {
													console.log('error')
												} else {
													data_top = Array.from(data_top.entries()).map((el) => {
														el[1]['rank'] = el[0] + 1
														return el[1]
													})
													this.setState({
														data_table: {
															columns: [
																{
																	label: 'Место',
																	field: 'rank',
																	sort: 'asc',
																	width: 100
																},
																{
																	label: 'Пользователь',
																	field: 'name',
																	sort: 'asc',
																	width: 300
																},
																{
																	label: 'Почта пользователя',
																	field: 'email_google',
																	sort: 'asc',
																	width: 300
																},
																{
																	label: 'Количество упоминаний',
																	field: 'mentions',
																	sort: 'asc',
																	width: 270
																}
															],
															rows: data_top,
															title: `Топ ${data_top.length} пользователей по упоминаниям за ` + getRusDate(event.point.name) + ' ' + year + ' года'
														}
													});
												}
											});
										}
									}
								}
							}
						}
					}
				});
			}
		});
	}

	componentDidMount() {
		this.print_general_zulip()
	}

	render() {
		return (
			<div>
				<div className="container_new">
					<div className="cap__container">
						<div className="cap">
							<span className="cap_text">Zulip</span>

						</div>
					</div>
					<div className="container__inner">
						{this.state.showSpinner ?
							<div className="spinner"><Spinner animation="border" variant="primary" className="spinner"/></div> : null}
						<figure className="highcharts-figure">
							<div id="container"></div>
						</figure>
						{
							(this.state.data_table.title !== undefined) ? (
								<div id="text_top"> {this.state.data_table.title}</div>
							) : (<div></div>)
						}
						<div id="datatableZulip"><DatatableZulipPage data_table={this.state.data_table}/></div>
					</div>
				</div>
			</div>
		)
	}
}

//Перевод месяцев на русский язык
function getRusDate(text) {
	var month = text.split(' ')[0];
	switch (month) {
		case 'Jan':
			return 'январь ';
		case 'Feb':
			return 'февраль ';
		case 'Mar':
			return 'март ';
		case 'Apr':
			return 'апрель ';
		case 'May':
			return 'май ';
		case 'June':
			return 'июнь ';
		case 'July':
			return 'июль ';
		case 'Aug':
			return 'август ';
		case 'Sept':
			return 'сентябрь ';
		case 'Oct':
			return 'октябрь ';
		case 'Nov':
			return 'ноябрь ';
		case 'Dec':
			return 'декабрь ';
		default:
			return 'январь ';
	}
}

//Перевод месяцев
function getDate(text) {
	switch (text) {
		case '0':
			return 'Jan';
		case '1':
			return 'Feb';
		case '2':
			return 'Mar';
		case '3':
			return 'Apr';
		case '4':
			return 'May';
		case '5':
			return 'June';
		case '6':
			return 'July';
		case '7':
			return 'Aug';
		case '8':
			return 'Sept';
		case '9':
			return 'Oct';
		case '10':
			return 'Nov';
		case '11':
			return 'Dec';
		default:
			return 'Jan';
	}
}

//Перевод месяцев
function getNumberDate(text) {
	switch (text) {
		case 'Jan':
			return '0';
		case 'Feb':
			return '1';
		case 'Mar':
			return '2';
		case 'Apr':
			return '3';
		case 'May':
			return '4';
		case 'June':
			return '5';
		case 'July':
			return '6';
		case 'Aug':
			return '7';
		case 'Sept':
			return '8';
		case 'Oct':
			return '9';
		case 'Nov':
			return '10';
		case 'Dec':
			return '11';
		default:
			return '0';
	}
}

export default ChartsGeneralZulip