import React, {memo, useEffect, useState} from 'react'
import Highcharts from 'highcharts/highstock';
import getData_jitsi from '../../../services/api/FetchJitsi';
import '../../../components/SimpleElements/line'
import Spinner from 'react-bootstrap/Spinner';

const ChartsJitsi = memo(({email, beginDate, endDate}) => {
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)

	const get_jitsi = (email, date_begin, date_end) => {
		let begin = new Date(date_begin);
		let end = new Date(date_end);
		let november = new Date('2021-11-30');

		if (november > begin && november < end) {
			//включает ноябрь
			//Получение данных от формматера Jitsi
			getData_jitsi(email, date_begin, '2021-11-30', function (data_jitsi) {
				getData_jitsi(email, '2021-12-01', date_end, function (data_jitsi_after_november) {
					setLoading(false)
					if (data_jitsi.hasOwnProperty('error')) {
						setError(true)
						return
					} else {
						let data_sorted = []
						let data_month = []

						for (let i = 0; i < data_jitsi_after_november["data"].length; i++) {
							data_jitsi['data'].push(data_jitsi_after_november['data'][i])
						}
						for (let i = 0; i < data_jitsi_after_november["stats"].length; i++) {
							data_jitsi['stats'].push(data_jitsi_after_november['stats'][i])
						}

						for (let i = 0; i < data_jitsi["stats"].length; i++) {
							data_sorted.push(data_jitsi['stats'][i]['sessions_time'])
							data_month.push(data_jitsi['stats'][i]['month'])
						}

						print_jitsi(data_jitsi, data_sorted, data_month)
					}
				});
			});
			return
		} else if (november < begin) {
			//После ноября
		} else if (november >= begin) {
			//До ноября
		}

		//Получение данных от формматера Jitsi
		getData_jitsi(email, date_begin, date_end, function (data_jitsi) {
			setLoading(false)
			if (data_jitsi.hasOwnProperty('error')) {
				setError(true)
			} else {
				setError(false)
				let data_sorted = []
				let data_month = []
				for (let i = 0; i < data_jitsi["stats"].length; i++) {
					data_sorted.push(data_jitsi['stats'][i]['sessions_time'])
					data_month.push(data_jitsi['stats'][i]['month'])
				}
				print_jitsi(data_jitsi, data_sorted, data_month)
			}
		});
	}

	useEffect(() => {
		setLoading(true)
		get_jitsi(email, beginDate, endDate)
	}, [email, beginDate, endDate])

	return (
		<div className="container_new">
			<div className="cap__container_stats">
				<div className="cap">
					<span className="cap_text">Jitsi</span>
				</div>
			</div>

			{
				loading ?
					<div className="spinner"><Spinner animation="border" variant="primary" className="spinner"/></div> :
					error ?
						<div className="noData">
							<div className="noData_inner"> Нет данных</div>
						</div> :
						<>
							{/*<div id="info_stats_zulip">Для получения более подробной информации нажмите на столбец диаграммы и*/}
							{/*	прокрутите вниз*/}
							{/*</div>*/}
							<div className="container_column_stats" id="id_container_column_stats">
								<div id="container_jitsi"></div>
							</div>
							<div className="container_pie_stats" id="id_container_pie_stats">
								<div className="row">
									<div id="container_pie_charts" className="offset-3 col-lg-6 mt-3"></div>
									{/*<div id="container_jitsi_pie_mentions" className="col-lg-6"></div>*/}
									<div id="container_jitsi_pie_messages" className="col-lg-6"></div>
								</div>
							</div>
						</>
			}
		</div>
	)

})

export default ChartsJitsi

function print_jitsi(data_jitsi, data_sorted, data_month) {
	//Формирование графика по Jitsi
	Highcharts.chart('container_jitsi', {
		title: {
			// text: 'Статистика Jitsi'
			text: ''
		},
		// subtitle: {
		// 	text: 'Для получения более подробной информации нажмите на столбец диаграммы и прокрутите вниз'
		// },
		exporting: {
			buttons: {
				contextButton: {
					enabled: false
				}
			}
		},
		xAxis: {
			categories: data_month,
			crosshair: true
		},
		yAxis: {
			allowDecimals: false,
			title: {
				text: 'Академические часы'
			}
		},
		series: [{
			name: 'Академические часы',
			type: 'column',
			colorByPoint: true,
			data: data_sorted,
			showInLegend: false,
			cursor: 'pointer',
			point: {
				events: {
					click: function () {
						var arr = [];
						for (let i = 0; i < data_jitsi["stats"][this.x]["pairs"].length; i++) {
							arr[i] = [];
							arr[i][0] = data_jitsi['stats'][this.x]['pairs'][i]['discipline'] + ' (Занятий: ' + data_jitsi['stats'][this.x]['pairs'][i]['amount'] + ')';
							arr[i][1] = data_jitsi['stats'][this.x]['pairs'][i]['time'];
							arr[i][2] = false;
						}
						//Формирование pie chart по Jitsi
						Highcharts.chart('container_pie_charts', {
							title: {
								text: data_jitsi['stats'][this.x]['month']
							},
							exporting: {
								buttons: {
									contextButton: {
										enabled: false
									}
								}
							},
							series: [{
								name: 'Ак.часов',
								name1: 'занятий',
								type: 'pie',
								allowPointSelect: true,
								keys: ['name', 'y', 'selected', 'sliced'],
								data: arr,
								showInLegend: false
							}]
						});
					}
				}
			}
		}]
	});
}
