import React, {useState} from 'react';
import Nav from 'react-bootstrap/Nav'
import './style.css'
import imgh from '../../NewDesign/HomeIconBlue.png';

const Navbar = () => {
	const [key] = useState('first');
	const ActiveStyle = {
		textAlign: 'center',
		width: '200px',
		'marginLeft': '10px',
		'marginTop': '10px'
	};
	const inActiveStyle = {
		...ActiveStyle
	};

	return (
		<div className={'nav-wrap'}>
			<a href="/">
				<img src={imgh} alt="logo" className="home"/>
			</a>
			<Nav variant="pills">
				<Nav.Item>
					<Nav.Link eventKey="generaljitsi_pill" id="generaljitsi_tab"
										style={key === 'first' ? ActiveStyle : inActiveStyle}>General Jitsi</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link eventKey="classroom_pill" id="classroom_tab" style={key === 'second' ? ActiveStyle : inActiveStyle}>General
						Zulip</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link eventKey="project_pill" id="project_tab" style={key === 'third' ? ActiveStyle : inActiveStyle}>
						Проектный рейтинг
					</Nav.Link>
				</Nav.Item>
			</Nav>
		</div>
	);
}

export default Navbar