import React from 'react'
import Highcharts from 'highcharts/highstock';
import getData_jitsi from '../../../services/api/FetchGeneralJitsi';
import getTop_jitsi from '../../../services/api/FetchTopGeneralJitsi';
import DatatableJitsiPage from '../../../components/Tables/Table';
import '../../../components/SimpleElements/style.css'
import drilldown from 'highcharts/modules/drilldown'
import Spinner from 'react-bootstrap/Spinner'
import '../../../components/SimpleElements/style.css'

drilldown(Highcharts);

class ChartsGeneralJitsi extends React.Component {

	constructor() {
		super();
		this.state = {data_table: {}, showSpinner: true}
	}

	//Отрисовка данных по общей статистике Jitsi
	print_general_jitsi() {
		//Получение данных
		getData_jitsi((data) => {
			if (data.hasOwnProperty('error')) {
				document.getElementById('container_jitsi').innerHTML = '<div class="noData"> <div class="noData_inner"> Нет данных </div></div>'
				return
			} else {
				this.setState({data_table: {}})
				let year = '';
				var dataTop = {}
				let options = {
					times: [],
					data_month: []
				};

				var data_month = {}
				for (let i = 0; i < data.length; i++) {
					if (data[i]['month'] === '*') {
						options.times.push({
							'name': data[i]['year'],
							'y': data[i]['times'],
							'drilldown': `${data[i]['year']}-times`
						})
					} else {
						if (!data_month[data[i]['year']]) {
							data_month[data[i]['year']] = {
								'name': data[i]['year'],
								'id': `${data[i]['year']}-times`,
								'data': []
							}
						}
						data_month[data[i]['year']]['data'].push([
							getDate(data[i]['month']), data[i]['times']
						])
					}
				}
				for (let key in data_month) {
					options.data_month.push(data_month[key])
				}
				this.setState({showSpinner: false})
				//Установка названия кнопки назад
				Highcharts.setOptions({
					lang: {
						drillUpText: '<< Назад'
					}
				});

				//График общей статистики по Jitsi
				Highcharts.chart('container_jitsi', {
					chart: {
						type: 'column',
						events: {
							drillup: (event) => {
								this.setState({data_table: {}})
							}
						}
					},
					title: {
						text: 'Общая статистика по сервису Jitsi'
					},
					subtitle: {
						text: 'Для получения более подробной информации нажмите на столбец диаграммы и прокрутите вниз'
					},
					accessibility: {
						announceNewData: {
							enabled: true
						}
					},
					xAxis: {
						type: 'category'
					},
					yAxis: {
						title: {
							text: 'Количеcтво часов'
						}

					},
					legend: {
						enabled: false
					},
					tooltip: {
						headerFormat: '<span style="font-size:11px">Кол-во часов:</span><br>',
						pointFormat: '<span style="color:{series.name}"><b>{point.y:.0f}</b></span><br/>'
					},

					series: [{
						name: 'Часы',
						data: options.times
					}],
					drilldown: {
						activeAxisLabelStyle: {
							'pointerEvents': 'none',
							'textDecoration': 'none',
							'color': 'initial'
						},
						breadcrumbs: {
							position: {
								align: 'right'
							}
						},
						series: options.data_month
					},
					plotOptions: {
						series: {
							events: {
								click: (event) => {
									if (parseInt(event.point.name)) { // Топ пользователей по году
										year = event.point.name
										// console.log(data_top["top_year"][event.point.name][0]["topUsers"])
										getTop_jitsi(year, 'null', (dataTop_get) => {
											dataTop = dataTop_get;
											dataTop = Array.from(dataTop.entries()).map((el) => {
												el[1]['rank'] = el[0] + 1
												return el[1]
											})
											for (let i = 0; i < dataTop.length; i++) {
												dataTop[i]['times'] = parseInt(dataTop[i]['times'])
											}
											this.setState({
												data_table: {
													columns: [
														{
															label: 'Место',
															field: 'rank',
															sort: 'asc',
															width: 100
														},
														{
															label: 'Пользователь',
															field: 'name',
															sort: 'asc',
															width: 300
														},
														{
															label: 'Почта пользователя',
															field: 'email_google',
															sort: 'asc',
															width: 300
														},
														{
															label: 'Количество часов в Jitsi',
															field: 'times',
															sort: 'asc',
															width: 270
														}
													],
													rows: dataTop,
													title: `Топ  ${dataTop.length} пользователей за ` + year + ' год'
												}
											});
										});
									} else { // Топ пользователей по месяцам за выбранный год
										// console.log("Месяц")
										// console.log(year)
										// console.log(event.point.name)
										// console.log(event.point.index)
										// console.log(data_top["top_year"][year][0]["top_month"][event.point.name])
										let month = getNumberDate(event.point.name)
										getTop_jitsi(year, month, (dataTop_get) => {
											dataTop = dataTop_get;
											for (let i = 0; i < dataTop.length; i++) {
												dataTop[i]['times'] = parseInt(dataTop[i]['times'])
											}
											dataTop = Array.from(dataTop.entries()).map((el) => {
												el[1]['rank'] = el[0] + 1
												return el[1]
											})
											this.setState({
												data_table: {
													columns: [
														{
															label: 'Место',
															field: 'rank',
															sort: 'asc',
															width: 100
														},
														{
															label: 'Пользователь',
															field: 'name',
															sort: 'asc',
															width: 300
														},
														{
															label: 'Почта пользователя',
															field: 'email_google',
															sort: 'asc',
															width: 300
														},
														{
															label: 'Количество часов в Jitsi',
															field: 'times',
															sort: 'asc',
															width: 270
														}
													],
													rows: dataTop,
													title: `Топ  ${dataTop.length} пользователей за ` + getRusDate(event.point.name) + ' ' + year + ' года'
												}
											});
										});
									}
								}
							}
						}
					}
				});
			}
		});
	}

	componentDidMount() {
		this.print_general_jitsi()
	}

	render() {
		return (
			<div className="container_new">
				<div className="cap__container">
					<div className="cap">
						<span className="cap_text">Jitsi</span>

					</div>
				</div>
				<div className="container__inner">
					{this.state.showSpinner ?
						<div className="spinner"><Spinner animation="border" variant="primary" className="spinner"/></div> : null}
					<figure className="highcharts-figure">
						<div id="container_jitsi"></div>
					</figure>
					{
						(this.state.data_table.title !== undefined) ? (
							<div id="text_top"> {this.state.data_table.title}</div>
						) : (<div></div>)
					}
					<div id="datatableJitsi"><DatatableJitsiPage data_table={this.state.data_table}/></div>
				</div>
			</div>
		)
	}
}

//Перевод месяцев на русский язык
function getRusDate(text) {
	var month = text.split(' ')[0];
	switch (month) {
		case 'Jan':
			return 'январь ';
		case 'Feb':
			return 'февраль ';
		case 'Mar':
			return 'март ';
		case 'Apr':
			return 'апрель ';
		case 'May':
			return 'май ';
		case 'June':
			return 'июнь ';
		case 'July':
			return 'июль ';
		case 'Aug':
			return 'август ';
		case 'Sept':
			return 'сентябрь ';
		case 'Oct':
			return 'октябрь ';
		case 'Nov':
			return 'ноябрь ';
		case 'Dec':
			return 'декабрь ';
		default:
			return 'январь ';
	}
}

//Перевод месяцев
function getDate(text) {
	switch (text) {
		case '0':
			return 'Jan';
		case '1':
			return 'Feb';
		case '2':
			return 'Mar';
		case '3':
			return 'Apr';
		case '4':
			return 'May';
		case '5':
			return 'June';
		case '6':
			return 'July';
		case '7':
			return 'Aug';
		case '8':
			return 'Sept';
		case '9':
			return 'Oct';
		case '10':
			return 'Nov';
		case '11':
			return 'Dec';
		default:
			return 'Jan';
	}
}

//Перевод месяцев
function getNumberDate(text) {
	switch (text) {
		case 'Jan':
			return '0';
		case 'Feb':
			return '1';
		case 'Mar':
			return '2';
		case 'Apr':
			return '3';
		case 'May':
			return '4';
		case 'June':
			return '5';
		case 'July':
			return '6';
		case 'Aug':
			return '7';
		case 'Sept':
			return '8';
		case 'Oct':
			return '9';
		case 'Nov':
			return '10';
		case 'Dec':
			return '11';
		default:
			return '0';
	}
}

export default ChartsGeneralJitsi