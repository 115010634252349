const requestURL_zulip = '/api/zulip'

function sendRequest(method, url, body = null) {
	const headers = {
		'Content-Type': 'application/json'
	}
	return fetch(url, {
		method: method,
		body: JSON.stringify(body),
		headers: headers

	}).then(response => {
		if (response.ok) {
			return response.json()
		}
		return response.json().then(error => {
			const e = new Error('Что то пошло не так')
			e.data = error
			throw e
		})
	})
}

function getData_zulip(email, begin, end, callback) {
	sendRequest('POST', requestURL_zulip, {
		studEmail: email,
		beginDate: begin,
		endDate: end
	}).then(data_zulip => {
		callback(data_zulip);
	}).catch(err => callback({
		error: err
	}))
}

export default getData_zulip