import React from 'react'
import './SearchPage.css'
import logo from './SearchPageLogo.png'
import fetchHelpers from '../../services/api/fetchHelpers'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class SearchPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			value: '',  // значение поля ввода
			slug: '',   // уникальное имя пользователя почты в домене miem.hse.ru
			timer: undefined,   // функция-таймер для отправки запроса предложения пользователей
			users: undefined,   // массив для отображения предложений пользователей
			isMessageShow: false,   // отображение ошибки на странице
			isFinderShow: true     // отображение предложений пользователей
		};

		// Регистрация функций класса, определение для них this
		this.handleChange = this.handleChange.bind(this);
		this.findStats = this.findStats.bind(this);
		this.clickItem = this.clickItem.bind(this);
		this.timerSetter = this.timerSetter.bind(this);
		this.hideFinder = this.hideFinder.bind(this);
	}

	handleChange(event) {
		// Ввод текста в поле ввода
		this.setState({value: event.target.value, slug: '', isFinderShow: true}); // Перезапись состояния компонента
		setTimeout(() => {
			// Добавление условия в стек вызовов
			if (this.state.value && this.state.value.trim() !== '') {
				this.timerSetter(); // установка таймера если строка непустая
			} else {
				this.setState({users: undefined}); // иначе сброс предложения пользователей
			}
		}, 10)
	}

	timerSetter() {
		// Установка таймера
		clearTimeout(this.state.timer); // перед этим таймер сбрасываем
		this.setState({
			timer: setTimeout(() => {
				fetchHelpers({
						// Запрос пользователей с сервера
						str: this.state.value,
						callback: data => {
							if (('error' in data['users']) || (data['users'].length === 0)) {
								this.setState({
									users: undefined,
									isMessageShow: false,
									slug: ''
								});
								return
							}
							let data_users = []
							for (let value of Object.values(data['users'])) {
								data_users.push({'name': value['name'], 'email': value['email_hse'], 'group': value['info']});
							}
							this.setState({
								users: data_users,
								isMessageShow: false,
								slug: ''
							});
						},
						wasIncorrect: data => {
							this.setState({
								users: undefined,
								isMessageShow: true,
								slug: ''
							});
						}
					}
				)
			}, 500)
		});
	}

	clickItem(item) {
		console.log(item)
		// Выбор пользователя из предложенных
		let temp = {
			// объект для записи в состояния
			value: item.name,
			users: undefined,
			slug: item.email.split('@')[0],
			email: item.email
		};
		this.setState(temp);
		localStorage.setItem('portfolio__search_init', JSON.stringify(temp)); // также записываем это состояние в localStorage
	}

	findStats(e, state) {
		// Обработчик перенаправления пользователя
		e.preventDefault(); // отменяем стандартное поведение формы - submit
		if (this.state.value !== '') {
			// при непустой строке ввода
			if (this.state.slug === '') {
				// если в slug нет данных, запрашиваем данные с сервера
				fetchHelpers({
					str: this.state.value,
					callback: data => {
						if (Object.keys(data).length === 1) {
							switch (state) {
								case 'stats':
									if (e.ctrlKey) {
										window.open(
											'?slug=' + data['users'][0]['email_hse'].split('@')[0],
											'_blank'
										);
									} else {
										window.location =
											'?slug=' + data['users'][0]['email_hse'].split('@')[0];
									}
									return
								case 'analytics':

									if (e.ctrlKey) {
										window.open(
											'analytics/?slug=' + data['users'][0]['email_hse'].split('@')[0],
											'_blank'
										);
									} else {
										window.location = 'analytics/?slug=' + data['users'][0]['email_hse'].split('@')[0];
									}
									return
								case 'nvr':
									if (e.ctrlKey) {
										window.open(
											'nvr/?slug=' + data['users'][0]['email_hse'].split('@')[0],
											'_blank'
										);
									} else {
										window.location = 'nvr/?slug=' + data['users'][0]['email_hse'].split('@')[0];
									}

									return
								default:
									if (e.ctrlKey) {
										window.open(
											'?slug=' + data['users'][0]['email_hse'].split('@')[0],
											'_blank'
										);
									} else {
										window.location = '?slug=' + data['users'][0]['email_hse'].split('@')[0];
									}
									return
							}
						}
					}
				})
			} else {
				// иначе перенаправляем пользователя по нужному пути
				switch (state) {
					case 'stats':
						if (e.ctrlKey) {
							window.open('?slug=' + this.state.slug, '_blank')
						} else {
							window.location = '?slug=' + this.state.slug;
						}
						return
					case 'analytics':
						if (e.ctrlKey) {
							window.open('analytics/?slug=' + this.state.slug, '_blank')
						} else {
							window.location = 'analytics/?slug=' + this.state.slug;
						}
						return
					case 'nvr':
						if (e.ctrlKey) {
							window.open('nvr/?slug=' + this.state.slug, '_blank')
						} else {
							window.location = 'nvr/?slug=' + this.state.slug;
						}
						return
					default:
						if (e.ctrlKey) {
							window.open('?slug=' + this.state.slug, '_blank')
						} else {
							window.location = '?slug=' + this.state.slug;
						}
						return
				}
			}
		}
	}

	hideFinder() {
		// Скрыть предложение пользователей
		this.setState({isFinderShow: false});
	}

	componentDidMount() {
		document.addEventListener('keydown', (e) => {
			// Добавление обработчика нажатия кнопки
			if (e.keyCode === 27 || e.code === 27) {
				// Если нажата кнопка esc - скрываем предложения пользователей
				this.hideFinder();
			}
		})
		let memory_init = localStorage.getItem('portfolio__search_init'); // получение предыдущих данных из lovalStorage
		if (memory_init) {
			// Если в lovalStorage были данные, переписываем состояния компонента
			this.setState(JSON.parse(memory_init));
		}
	}

	render() {
		let list = null;
		if (!!this.state.users) {
			// Формирование массива предложения пользователей в JSX формат
			list = this.state.users.map((value, key) => {
				return <div className="item" key={key} onClick={() => {
					this.clickItem(value)
				}}>
					<span>{value.name}{value.group ? `, ${value.group}` : ' '}</span>
				</div>
			})
		}
		return (
			<div id="view_default">
				<form onSubmit={this.findStats} className="container">
					<div className="image_port"><img src={logo} alt="logo" className="img-fluid"/></div>
					<label className="view_default__label" htmlFor="view_default__input">
						Поиск статистики студента или сотрудника</label>
					<OverlayTrigger key={'left'} placement={'right'} overlay={<Tooltip id="tooltip-left" className="overlay">
						<p>В разделе "Статистика" находятся статистика пользователя по "Jitsi", "Zulip"</p>
						<p>В разделе "Записи занятий" отображаются ссылки на запись занятий пользователя</p>
						В разделе «Аналитика» находятся определения характера отзывов на пользователя и часто встречающихся
						уникальных слов пользователя в Git.</Tooltip>}>
                <span style={{borderRadius: '50%', width: '20px', height: '20px'}} className="d-inline-block">
                    <Button disabled style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											padding: 0,
											border: 'none',
											pointerEvents: 'none',
											borderRadius: '50%',
											width: '20px',
											height: '20px',
											background: 'gray',
											marginTop: '3px'
										}}>?</Button>
                </span>
					</OverlayTrigger>
					<div className="input-group mb-3">
						<div className={this.state.users && this.state.isFinderShow ? 'menu-items_visible finder' : 'finder'}>
							<input type="text" value={this.state.value} onChange={this.handleChange}
										 className="form-control visible"
										 placeholder="Введите ФИО или почту"
										 aria-label="Введите ФИО или почту"
										 aria-describedby="view_default__button"
										 id="view_default__input"
										 autoComplete="off"/>
							<div className="open-visible">
								<div className="box">
									{list}
									<div className={this.state.isMessageShow ? 'onMessage isShow' : 'onMessage'}>Ошибка!</div>
								</div>
							</div>
						</div>
					</div>
					<div className="list_button row">
						<div className="col text-center">
							<button type="button" onClick={(e) => this.findStats(e, 'analytics')}
											className="btn btn-primary w-100 mx-0"> Найти
							</button>
						</div>
						{/*  <div className="col-12 col-md-4">
                            <button type="button" onClick={(e) => this.findStats(e, "stats")} className="btn btn-primary w-100 mx-0" > Статистика </button>
                        </div>
                        <div className="col-12 col-md-4">
                            <button type="button" onClick={(e) => this.findStats(e, "nvr")} className="btn btn-primary w-100 mx-0"> Записи занятий </button>
                        </div>*/}

					</div>
				</form>

				<div className={'buttons_container-wrap container'}>
					<nav className={'buttons_container'}>
						<div className="buttons_container-item">
							<div className={'buttons_title'}>
								Общий рейтинг
							</div>
							<div className="buttons_description">
								Рейтинг активности
								пользователей по сервисам Jitsi и Zulip
							</div>
							<a href="/general" className="btn btn-primary w-50 mx-0">
								Общий рейтинг
							</a>
						</div>

						<div className="buttons_container-item">
							<div className={'buttons_title'}>
								Граф пользователей МИЭМ
							</div>
							<div className="buttons_description">
								Визуальное представление участников
								проектной деятельности МИЭМ
							</div>
							<a href="https://portfolio.miem.hse.ru/project-graph" className="btn btn-primary w-50 mx-0">
								Проектный граф
							</a>
						</div>
					</nav>
				</div>
			</div>
		)
	}
}

export default SearchPage
