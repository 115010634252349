import React from 'react';
import './Sentiment.css';
import getSentiment from '../../../services/api/FetchSentiment';
import Highcharts from 'highcharts/highstock';
import fetchHelpers from '../../../services/api/fetchHelpers';

var d = {};
for (var i in window.location.search.replace('?', '').split('&')) {
	d[window.location.search.replace('?', '').split('&')[i].split('=')[0]] =
		window.location.search.replace('?', '').split('&')[i].split('=')[1];
}
let email = d['slug'] ? d['slug'] : '';

// ********** Инициализация даты *************
let date = new Date();
let endDate_init = date.toISOString().slice(0, 10);
date.setDate(date.getDate() - 7);
let startDate_init = date.toISOString().slice(0, 10);

class Sentiment extends React.Component {
	constructor() {
		super();

		// Определение состояний компонента
		this.state = {
			currentState: 'init', // changes to 'download', 'readyToShow', 'someError'
			inputData: [], // массив для хранения входных данных
			email: email,
			startDate: startDate_init,
			endDate: endDate_init,
			errorMessage: '',
			timer: undefined,
			users: undefined,
			isMessageShow: false
		};

		// Определение функций компонента для доступа к this
		this.fetchUser = this.fetchUser.bind(this);
		this.updateResults = this.updateResults.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.timerSetter = this.timerSetter.bind(this);
		this.changeData = this.changeData.bind(this);
	}

	fetchUser() {
		/**
		 * Запрос на сервер
		 */
		this.setState({currentState: 'download'});
		let options = {
			data: {
				beginDate: this.state.startDate,
				endDate: this.state.endDate,
				email: this.state.email
			},
			callback: (data) => {
				this.setState({inputData: data}, () => {
					// console.log(typeof data);
					if (data.length) {
						this.setState({currentState: 'readyToShow'});
						this.updateResults(data)
					}
				});
			},
			error: (str) => {
				this.setState({
					currentState: 'error',
					errorMessage: str
				});
			}
		};
		getSentiment(options);
	}

	updateResults(data) {
		let options = {
			categories: [],
			series: [
				{
					name: 'positive',
					data: [],
					color: '#008000'
				},
				{
					name: 'negative',
					data: [],
					color: '#d32f2f'
				},
				{
					name: 'neutral',
					color: '#616161',
					data: []
				},
				{
					name: 'humor',
					color: '#FFFF00',
					data: []
				}
			]
		};

		let currentMonth = '';
		let currentIndex = -1;

		for (let i = 0; i < data.length; i++) {
			if (currentMonth !== data[i].date.slice(-7)) {
				currentMonth = data[i].date.slice(-7);
				options.categories.push(currentMonth);
				currentIndex++;
			}
			addValue(options.series, currentIndex, data[i].value);
		}

		renameSeries(options.series);
		this.showResults(options);

		function addValue(series, index, value) {
			for (let i = 0; i < series.length; i++) {
				if (series[i].data.length < index + 1) {
					series[i].data.push(0);
				}
				if (series[i].name === value) {
					series[i].data[index] = series[i].data[index] + 1;
				}
			}
		}

		function renameSeries(series) {
			series.forEach((element) => {
				switch (element.name) {
					case 'neutral':
						element.name = 'Нейтральные отзывы';
						break;
					case 'humor':
						element.name = 'Отзывы с юмором';
						break;
					case 'positive':
						element.name = 'Позитивные отзывы';
						break;
					case 'negative':
						element.name = 'Негативные отзывы';
						break;
					default:
						element.name = 'Стиль отзыва отсутствует на странице';
				}
			});
		}
	}

	showResults(options = {}) {
		Highcharts.chart('chartContent', {
			chart: {
				type: 'column'
			},
			title: {
				text: 'Анализ отзывов на пользователя'
			},
			xAxis: {
				categories: options.categories
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Процент от общего количества отзывов'
				}
			},
			tooltip: {
				pointFormat:
					'<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
				shared: true
			},
			plotOptions: {
				column: {
					stacking: 'percent'
				}
			},
			series: options.series
		})

	}

	handleEmailChange(event) {
		/**
		 * Изменение поля ввода почты
		 */
		this.setState({email: event.target.value}, () => {
			this.timerSetter();
		});
	}

	timerSetter() {
		clearTimeout(this.state.timer);
		this.setState({
			timer: setTimeout(() => {
				fetchHelpers({
					str: this.state.email,
					callback: (data) => {
						if ('error' in data['users'] || data['users'].length === 0) {
							this.setState({
								users: undefined,
								isMessageShow: false
							});
							return;
						}
						let data_users = [];
						for (let value of Object.values(data['users'])) {
							data_users.push({
								name: value['name'],
								email: value['email_hse'],
								group: value['info']
							});
						}
						this.setState({
							users: data_users,
							isMessageShow: false
						});
					},
					wasIncorrect: (data) => {
						this.setState({
							users: undefined,
							isMessageShow: true
						});
					}
				});
			}, 500)
		});
	}

	clickItem(item) {
		this.setState({
			value: item.name,
			users: undefined,
			email: item.email
		});
	}

	changeData(startDate, endDate) {
		this.setState(
			{
				startDate: startDate.format('YYYY-MM-DD'),
				endDate: endDate.format('YYYY-MM-DD')
			},
			() => {
				this.fetchUser();
			}
		);
	}

	componentDidMount() {
		setTimeout(this.fetchUser, 500);
	}

	render() {
		// манипуляция классами при необходимости
		let SentimentClasses = 'Sentiment__container';
		if (this.state.currentState === 'download') {
			// добавление класса при загрузке элементов
			SentimentClasses += ' Sentiment__container_download';
		}
		if (this.state.currentState === 'error') {
			// добавление класса при загрузке элементов
			SentimentClasses += ' Sentiment__container_error';
		}

		let list = null;
		if (!!this.state.users) {
			list = this.state.users.map((value, key) => {
				return (
					<div
						className="item"
						key={key}
						onClick={() => {
							this.clickItem(value);
						}}
					>
						<span> {value.name} </span>
						<span className="item_group">
              {' '}
							{value.group ? ` ${value.group}` : ' '}{' '}
            </span>
					</div>
				);
			});
		}

		return (
			<div className="container_new">
				<div className={SentimentClasses}>
					<div className="Sentiment__container__header">
						<div className="mb-3 position-relative">
							<div className="cap__container">
								<div className="cap">
									<span className="cap_text">Сентимент анализ</span>

								</div>
							</div>
							<div
								className={
									this.state.users ? 'open-visible isShow' : 'open-visible'
								}
							>
								<div className="box">
									{list}
									<div
										className={
											this.state.isMessageShow ? 'onMessage isShow' : 'onMessage'
										}
									>
										Ошибка!
									</div>
								</div>
							</div>
						</div>
						<div className="data__search">
							<div className="mb-3">

							</div>
						</div>
					</div>
					<div className="Sentiment__container__content">
						<div id="chartContent"></div>
					</div>
					<div className="Sentiment__plug Sentiment__plug-download">
						<h2>Подождите, информация загружается...</h2>
					</div>
					<div className="Sentiment__plug Sentiment__plug-error">
						<h2>{this.state.errorMessage}</h2>
					</div>
				</div>
			</div>
		);
	}
}

export default Sentiment;
