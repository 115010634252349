
const requestURL_tags = '/api/formatters/reccomendations.getReccomendations'

function sendRequest(method, url, body = null){
    const headers ={
        'Content-Type': 'application/json'
    }
    return fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: headers

    }).then(response => {
        if (response.ok){
        return response.json()

        }
        return response.json().then(error =>{
            const e = new Error('Что то пошло не так')
            e.data = error
            throw e
        })
    })
}

function getReccomendations(slug, callback){
    sendRequest('POST', requestURL_tags, {
        studName: slug
    }).then(courses => {
        callback(courses);
    }).catch(err => console.log(err))
}

export default getReccomendations