
const requestURL_nvr = '/api/recordings'

function sendRequest(method, url, body = null){
    const headers ={
        'Content-Type': 'application/json'
    }
    return fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: headers

    }).then(response => {
        if (response.ok){
        return response.json()

        }
        return response.json().then(error =>{
            const e = new Error('Что то пошло не так')
            e.data = error
            throw e
        })
    })
}

let getNVR = options => {
    sendRequest('POST', requestURL_nvr, {
        studEmail: options.data.email,
        beginDate: options.data.beginDate,
        endDate: options.data.endDate

    }).then(data => {
        options.callback(data);
    }).catch(err => console.log(err))
}

export default getNVR