import React, {memo, useEffect, useState} from 'react'
import Highcharts from 'highcharts/highstock';
import getData_zulip from '../../../services/api/FetchZulip';
import Spinner from 'react-bootstrap/Spinner';

const ChartsZulip = memo(({email, beginDate, endDate}) => {
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)

	const print_zulip = (email, date_begin, date_end) => {
		//Получение данных от форматтера Zulip
		getData_zulip(email, date_begin, date_end, function (data_zulip) {
			setLoading(false)

			if (data_zulip.hasOwnProperty('error') || data_zulip === 'error') {
				console.log(data_zulip)
				setError(true)
			} else {
				setError(false)
				let categories = []
				let messages = []
				let mentions = []
				let streams = []
				for (let i = 0; i < data_zulip["stats"].length; i++) {
					let elem = data_zulip['stats'][i]
					categories.push(elem.beginDate.split(' ')[1] + ' ' + elem.beginDate.split(' ')[3])
					mentions.push(elem.mentionCount);
					messages.push(elem.messageCount);
					streams.push(elem.streams);
				}
				let data_math_stats = {
					mentionsLowerBorder: data_zulip['properties']['mentions']['lowerBorder'],
					mentionsUpperBorder: data_zulip['properties']['mentions']['upperBorder'],
					mentionsAverage: data_zulip['properties']['mentions']['average'],
					messagesLowerBorder: data_zulip['properties']['messages']['lowerBorder'],
					messagesUpperBorder: data_zulip['properties']['messages']['upperBorder'],
					messagesAverage: data_zulip['properties']['messages']['average']
				}
				paintMainGraph('container_zulip_mention', 'container_zulip_message', categories, mentions, messages, streams, data_math_stats)
			}
		});

	}

	useEffect(() => {
		setLoading(true)
		print_zulip(email, beginDate, endDate)
	}, [email, beginDate, endDate])

	return (
		<div className="container_new">
			<div className="cap__container_stats">
				<div className="cap">
					<span className="cap_text">Zulip</span>
				</div>
			</div>

			{
				loading ?
					<div className="spinner"><Spinner animation="border" variant="primary" className="spinner"/></div> :
					error ?
						<div className="noData">
							<div className="noData_inner"> Нет данных</div>
						</div> :
						<>
							{/*<div id="info_stats_zulip">Для получения более подробной информации нажмите на столбец диаграммы и*/}
							{/*	прокрутите вниз*/}
							{/*</div>*/}
							<div className="container_column_stats" id="id_container_column_stats">
								<div className="row">
									<div id="container_zulip_mention" className="col-lg-6"></div>
									<div id="container_zulip_message" className="col-lg-6"></div>
								</div>
							</div>
							<div className="container_pie_stats" id="id_container_pie_stats">
								<div className="row">
									<div id="container_zulip_pie_mentions" className="col-lg-6"></div>
									<div id="container_zulip_pie_messages" className="col-lg-6"></div>
								</div>
							</div>
						</>
			}
		</div>
	)
})

export default ChartsZulip

//Отрисовка графика Zulip
function paintMainGraph(id_mention, id_message, categories, mentions, messages, streams, data_math_stats) {
	document.getElementById('container_zulip_pie_mentions').innerHTML = '';
	document.getElementById('container_zulip_pie_messages').innerHTML = '';
	//Highcharts по упоминаниям
	Highcharts.chart(id_mention, {
		title: false,
		subtitle: false,
		xAxis: {
			categories: categories,
			crosshair: true
		},
		yAxis: {
			min: 0,
			title: {text: 'Упоминаний'},
			minorGridLineWidth: 0,
			gridLineWidth: 0,
			alternateGridColor: null,
			plotBands: [{
				from: data_math_stats.mentionsLowerBorder,
				to: data_math_stats.mentionsUpperBorder,
				color: 'rgba(172, 240, 209, 0.3)'
			}],
			plotLines: [{
				value: data_math_stats.mentionsAverage,
				color: 'rgba(132, 200, 169, 0.8)',
				width: 1,
				label: {
					text: '',
					style: {
						color: '#606060'
					},
					useHTML: true
				},
				dashStyle: 'LongDash',
				zIndex: 1
			}]
		},
		series: [
			{
				type: 'column',
				name: 'Упоминаний',
				colorByPoint: true,
				data: mentions,
				showInLegend: false,
				cursor: 'pointer',
				point: {
					events: {
						click: function (e) {
							var dataMention = [];
							streams[this.index].forEach(element => {
								dataMention.push({
									name: element.name,
									y: element.mentionCount
								});
							});
							var title = 'Упоминаний за ' + getRusDate(this.category);
							paintPieDiagram('container_zulip_pie_mentions', title, dataMention);

							var dataMessage = [];
							streams[this.index].forEach(element => {
								dataMessage.push({
									name: element.name,
									y: element.messageCount
								});
							});
							title = 'Сообщений за ' + getRusDate(this.category);
							paintPieDiagram('container_zulip_pie_messages', title, dataMessage);
						}
					}
				}
			}
		],
		navigation: {
			annotationsOptions: {
				visible: false
			},
			buttonOptions: {
				enabled: false
			}

		}
	});

	//Highcharts по сообщениям
	Highcharts.chart(id_message, {
		title: false,
		subtitle: false,
		xAxis: {
			categories: categories,
			crosshair: true
		},
		yAxis: {
			min: 0,
			title: {text: 'Сообщений'},
			minorGridLineWidth: 0,
			gridLineWidth: 0,
			alternateGridColor: null,
			plotBands: [{
				from: data_math_stats.messagesLowerBorder,
				to: data_math_stats.messagesUpperBorder,
				color: 'rgba(172, 240, 209, 0.3)'
			}],
			plotLines: [{
				value: data_math_stats.messagesAverage,
				color: 'rgba(132, 200, 169, 0.8)',
				width: 1,
				label: {
					text: '',
					style: {
						color: '#606060'
					},
					useHTML: true
				},
				dashStyle: 'LongDash',
				zIndex: 1
			}]
		},
		series: [
			{
				type: 'column',
				name: 'Сообщений',
				colorByPoint: true,
				data: messages,
				showInLegend: false,
				point: {
					events: {
						click: function (e) {
							var dataMention = [];
							streams[this.index].forEach(element => {
								dataMention.push({
									name: element.name,
									y: element.mentionCount
								});
							});
							var title = 'Упоминаний за ' + getRusDate(this.category);
							paintPieDiagram('container_zulip_pie_mentions', title, dataMention);

							var dataMessage = [];
							streams[this.index].forEach(element => {
								dataMessage.push({
									name: element.name,
									y: element.messageCount
								});
							});
							title = 'Сообщений за ' + getRusDate(this.category);
							paintPieDiagram('container_zulip_pie_messages', title, dataMessage);

						}
					}
				}
			}
		],
		navigation: {
			annotationsOptions: {
				visible: false
			},
			buttonOptions: {
				enabled: false
			}

		}
	});
}

//Отрисовка pie charts Zulip
function paintPieDiagram(id, title, data) {

	Highcharts.chart(id, {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie'
		},
		title: {
			text: title
		},
		tooltip: {
			pointFormat: '{series.name}: <b>{point.y}</b>'
		},

		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					format: '<b>{point.name}</b>',
					connectorColor: 'silver'
				},
				size: 200
			}
		},
		series: [{
			name: title.split(' ')[0],
			data: data
		}],
		navigation: {
			buttonOptions: {
				enabled: false
			}
		}
	});
}

//Получение названий месяцев на русском языке
function getRusDate(text) {
	var month = text.split(' ')[0];
	switch (month) {
		case 'Jan':
			return 'январь ' + text.split(' ')[1];
		case 'Feb':
			return 'февраль ' + text.split(' ')[1];
		case 'Mar':
			return 'март ' + text.split(' ')[1];
		case 'Apr':
			return 'апрель ' + text.split(' ')[1];
		case 'May':
			return 'май ' + text.split(' ')[1];
		case 'Jun':
			return 'июнь ' + text.split(' ')[1];
		case 'Jul':
			return 'июль ' + text.split(' ')[1];
		case 'Aug':
			return 'август ' + text.split(' ')[1];
		case 'Sep':
			return 'сентябрь ' + text.split(' ')[1];
		case 'Oct':
			return 'октябрь ' + text.split(' ')[1];
		case 'Nov':
			return 'ноябрь ' + text.split(' ')[1];
		case 'Dec':
			return 'декабрь ' + text.split(' ')[1];
		default:
			return 'январь ' + text.split(' ')[1];
	}
}