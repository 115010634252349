import fetchHelpers from '../services/api/fetchHelpers';

export const promisedFetchHelper = (slug) => new Promise((resolve) => {
	fetchHelpers({
		str: slug,
		callback: (res) => {
			resolve(res)
		}
	})
})

export const updateActualUser = async () => {
	const params = new URLSearchParams(window.location.search);
	const slug = params.get('slug')

	const result = await promisedFetchHelper(slug)

	let data = {
		email: '',
		slug: '',
		value: ''
	}

	if (slug && result) {
		data = {
			email: result.users[0].email_hse || '',
			slug: slug || '',
			value: result.users[0].name || ''
		}
	}

	localStorage.setItem('portfolio__search_init', JSON.stringify(data))

	return data
}