import React, {useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tab from 'react-bootstrap/Tab'
import ChooseSelect from '../NewDesign/ChooseSelect';
import Personal from '../NewDesign/Personal';
import Header from '../NewDesign/Header';
import {AuthContext} from '../../context/AuthContext';

function ElementsGeneralStats() {
	const {value} = useContext(AuthContext)

	return (
		<Tab.Container id="left-tabs-example" defaultActiveKey="sentiment_pill">
			<Header></Header>
			<Personal value={value}></Personal>
			<div className="analitcs">
				<ChooseSelect></ChooseSelect>
			</div>
		</Tab.Container>
	);
}

export default ElementsGeneralStats