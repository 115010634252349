import React from 'react';
import './style.css'

const Line = () => {
    return( 
        <>
            <div id="v1"></div>
        </>
    );
}

export default Line