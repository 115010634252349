import React from 'react'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import './CompetenceTags.css'
import Spinner from 'react-bootstrap/Spinner'
import '../../../components/SimpleElements/style.css'
import getCompetenceTags from '../../../services/api/FetchCompetenceTags'
import ReactWordcloud from 'react-wordcloud';

//Получение slug из параметра url строки
var parameters = {}
for (var i in window.location.search.replace('?', '').split('&')) {
	parameters[window.location.search.replace('?', '').split('&')[i].split('=')[0]] = window.location.search.replace('?', '').split('&')[i].split('=')[1]
}
let slug = parameters['slug']

/* TODO: Здесь проблема в производительности */
//Параметры для построения react-wordcloud
const options = {
	rotations: 1,
	rotationAngles: [0, 0],
	fontSizes: [30, 50],
	padding: 10,
	enableOptimizations: true
};

class CompetenceTags extends React.Component {
	constructor() {
		super();
		this.state = {words: [], showSpinner: true}
	}

	componentDidMount() {
		//Получение данных от форматтера аналитики "Теги компетенций"
		getCompetenceTags(slug, (data) => {
			console.log(data['tags'])
			this.setState({showSpinner: false})
			if (data['tags'] === undefined) {
				return
			}
			this.setState({words: data['tags']})
		})
	}

	render() {
		return (
			<div className="container_new">
				<div className="default">
					<div className="cap__container">
						<div className="cap">
							<span className="cap_text">Теги компетенций</span>
						</div>
					</div>
					{
						this.state.showSpinner ?
							<div className="spinner"><Spinner animation="border" variant="primary" className="spinner"/></div> :
							<div className="word-cloud__wrap">
								<ReactWordcloud
									words={this.state.words}
									options={options}
								/>
							</div>
					}
				</div>
			</div>
		)
	}
}

export default CompetenceTags