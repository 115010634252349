import React from 'react';
import img from './AnaliticsImg.png';
import imgv from './Vector.png';
import imgs from './StatsImg.png';
import imgr from './RecordImg.png';

const ChooseTabs = ({toggleState, toggleTab}) => {
	return (
		<div className="choose__tabs">
			<div className="text-analitcs">Персональная статистика пользователя</div>
			<div className="arrows">
				<div className={toggleState === 1 ? 'active-record record' : 'active-record'}
						 onClick={() => toggleTab(1)}>
					<div className="record__img-wrap">
						<img src={img} alt="logo" className="img-fluid"/>
					</div>
					<span className="record-text__text">Аналитика</span>
				</div>
				<div className="vector"><img src={imgv} alt="logo" className="img-fluid"/></div>
				<div className={toggleState === 2 ? 'active-record record' : 'active-record'}
						 onClick={() => toggleTab(2)}>
					<div className="record__img-wrap">
						<img src={imgs} alt="logo" className="img-fluid"/>
					</div>
					<span className="record-text__text">Статистика</span>
				</div>
				<div className="vector"><img src={imgv} alt="logo" className="img-fluid"/></div>
				<div className={toggleState === 3 ? 'active-record record' : 'active-record'}
						 onClick={() => toggleTab(3)}>
					<div className="record__img-wrap">
						<img src={imgr} alt="logo" className="img-fluid rec"/>
					</div>
					<span className="record-text__text rec_text">Записи занятий</span>
				</div>
			</div>
			<div className="line"></div>
		</div>
	);
};

export default ChooseTabs;