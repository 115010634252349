import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tab from 'react-bootstrap/Tab'
import DatePicker from './DatePicker';
import moment from 'moment'

var parameters = {}
for (var i in window.location.search.replace('?', '').split('&')) {
	parameters[window.location.search.replace('?', '').split('&')[i].split('=')[0]] = window.location.search.replace('?', '').split('&')[i].split('=')[1]
}
let email = parameters['slug'] + '@miem.hse.ru'

var begin_date = moment().subtract(1, 'years')
var end_date = moment()

if (parameters['begin_date']) {
	begin_date = moment(parameters['begin_date'])
}
if (parameters['end_date']) {
	end_date = moment(parameters['end_date'])
}

class Elements extends React.Component {

	constructor() {
		super();
		this.state = {data: [email, begin_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')]};
		this.changeData = this.changeData.bind(this);
	}

	//Получение данных от DataPicker
	changeData(start, end) {
		let begin_format = start.format('YYYY-MM-DD')
		let end_format = end.format('YYYY-MM-DD')
		this.setState({
				data: [email, begin_format, end_format]
			}
		);

		this.props.updateChartData(begin_format, end_format)
	}

	render() {
		return (
			<Tab.Container id="date-picker" defaultActiveKey="jitsi_pill">
				<DatePicker id={'jitsu_date-picker'} changeData={this.changeData} begin_date={begin_date} end_date={end_date}/>
			</Tab.Container>
		);
	}
}

export default Elements