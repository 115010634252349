import React, {useState} from 'react';
import Nav from 'react-bootstrap/Nav'

const ProjectNavbar = () => {
	const [key] = useState('first');
	const ActiveStyle = {
		textAlign: 'center',
		width: '200px',
		'marginLeft': '10px',
		'marginTop': '10px'
	};
	const inActiveStyle = {
		...ActiveStyle
	};

	return (
		<Nav variant="pills">
			<Nav.Item>
				<Nav.Link eventKey="zulip" style={key === 'first' ? ActiveStyle : inActiveStyle}>
					Zulip
				</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link eventKey="taiga" style={key === 'second' ? ActiveStyle : inActiveStyle}>
					Taiga
				</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link eventKey="wekan" style={key === 'third' ? ActiveStyle : inActiveStyle}>
					Wekan
				</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link eventKey="gitlab" style={key === 'third' ? ActiveStyle : inActiveStyle}>
					Gitlab
				</Nav.Link>
			</Nav.Item>
		</Nav>
	);
}

export default ProjectNavbar