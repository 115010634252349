import MyJson from './Sentiment.json';

function getSentiment(options) {
	if (true) {
		options.callback(MyJson);
	} else {
		options.error('Some error text for user');
	}
}

export default getSentiment;
