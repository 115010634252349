import React from 'react'
import Highcharts from 'highcharts/highstock';
import DatatableJitsiPage from '../../../components/Tables/Table';
import '../../../components/SimpleElements/style.css'
import drilldown from 'highcharts/modules/drilldown'
import Spinner from 'react-bootstrap/Spinner'
import '../../../components/SimpleElements/style.css'
import getTop_project from '../../../services/FetchTopGeneralProject';

drilldown(Highcharts);

class ChartsGeneralProject extends React.Component {

	constructor() {
		super();
		this.state = {data_table: {}, showSpinner: true}
	}

	//Отрисовка данных по общей статистике Jitsi
	print_general_jitsi() {
		//Получение данных
		getTop_project(this.props.type, (data) => {
			if (data === 'error') {
				document.getElementById('datatableJitsi').innerHTML = '<div class="noData"> <div class="noData_inner"> Нет данных </div></div>'
				return
			} else {
				if (!data && !Array.isArray(data)) {
					return;
				}

				let localData = data.map(item => {
					// return 1
					return {
						rank: item.percentile_rank,
						name: item.userInfo.name.fullname.replaceAll('-', ' '),
						email_google: item.userInfo.emails[0],
						rating: item.project_rating
					}
				})

				this.setState({
					data_table: {
						columns: [
							{
								label: 'Место',
								field: 'rank',
								sort: 'asc',
								width: 100
							},
							{
								label: 'Пользователь',
								field: 'name',
								sort: 'asc',
								width: 300
							},
							{
								label: 'Почта пользователя',
								field: 'email_google',
								sort: 'asc',
								width: 300
							},
							{
								label: 'Проектный рейтинг',
								field: 'rating',
								sort: 'asc',
								width: 270
							}
						],
						rows: localData,
						title: `Топ 100 пользователей ${this.props.type}`
					},
					showSpinner: false
				})
			}
		});
	}

	componentDidMount() {
		this.print_general_jitsi()
	}

	render() {
		return (
			<div className="container_new">
				<div className="container__inner container__inner-project">
					{this.state.showSpinner ?
						<div className="spinner"><Spinner animation="border" variant="primary" className="spinner"/></div> : null}
					<figure className="highcharts-figure">
						<div id={`${this.props.type}_container`}></div>
					</figure>
					{
						(this.state.data_table.title !== undefined) ? (
							<div id="text_top"> {this.state.data_table.title}</div>
						) : (<div></div>)
					}
					<div id={`${this.props.type}_container`}><DatatableJitsiPage data_table={this.state.data_table}/></div>
				</div>
			</div>
		)
	}
}

//Перевод месяцев на русский язык
function getRusDate(text) {
	var month = text.split(' ')[0];
	switch (month) {
		case 'Jan':
			return 'январь ';
		case 'Feb':
			return 'февраль ';
		case 'Mar':
			return 'март ';
		case 'Apr':
			return 'апрель ';
		case 'May':
			return 'май ';
		case 'June':
			return 'июнь ';
		case 'July':
			return 'июль ';
		case 'Aug':
			return 'август ';
		case 'Sept':
			return 'сентябрь ';
		case 'Oct':
			return 'октябрь ';
		case 'Nov':
			return 'ноябрь ';
		case 'Dec':
			return 'декабрь ';
		default:
			return 'январь ';
	}
}

//Перевод месяцев
function getDate(text) {
	switch (text) {
		case '0':
			return 'Jan';
		case '1':
			return 'Feb';
		case '2':
			return 'Mar';
		case '3':
			return 'Apr';
		case '4':
			return 'May';
		case '5':
			return 'June';
		case '6':
			return 'July';
		case '7':
			return 'Aug';
		case '8':
			return 'Sept';
		case '9':
			return 'Oct';
		case '10':
			return 'Nov';
		case '11':
			return 'Dec';
		default:
			return 'Jan';
	}
}

//Перевод месяцев
function getNumberDate(text) {
	switch (text) {
		case 'Jan':
			return '0';
		case 'Feb':
			return '1';
		case 'Mar':
			return '2';
		case 'Apr':
			return '3';
		case 'May':
			return '4';
		case 'June':
			return '5';
		case 'July':
			return '6';
		case 'Aug':
			return '7';
		case 'Sept':
			return '8';
		case 'Oct':
			return '9';
		case 'Nov':
			return '10';
		case 'Dec':
			return '11';
		default:
			return '0';
	}
}

export default ChartsGeneralProject