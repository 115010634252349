const requestURL_jitsi = '/api/jitsi'

function sendRequest(method, url, body = null) {
	const headers = {
		'Content-Type': 'application/json'
	}
	return fetch(url, {
		method: method,
		body: JSON.stringify(body),
		headers: headers

	}).then(response => {
		if (response.ok) {
			return response.json()

		}
		return response.json().then(error => {
			const e = new Error('Что то пошло не так')
			e.data = error
			throw e
		})
	})
}

function getData_jitsi(email, begin, end, callback) {
	sendRequest('POST', requestURL_jitsi, {
		studEmail: email,
		beginDate: begin,
		endDate: end
	}).then(data_jitsi => {
		callback(data_jitsi);
	}).catch(err => callback({error: err}))
}

export default getData_jitsi