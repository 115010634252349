import React from 'react';
import './NVR.css';
import getNVR from '../../services/api/FetchNVR';
import DateRangePicker from 'react-bootstrap-daterangepicker';

let parameters = {};
for (let i in window.location.search.replace('?', '').split('&')) {
	parameters[
		window.location.search.replace('?', '').split('&')[i].split('=')[0]
		] = window.location.search.replace('?', '').split('&')[i].split('=')[1];
}
let email = parameters['slug'] + '@edu.hse.ru';

let date = new Date();
let endDate = date.toISOString().slice(0, 10).split('-').reverse().join('.');

date.setDate(date.getDate() - 60);
let startDate = date.toISOString().slice(0, 10).split('-').reverse().join('.');

class NVR extends React.Component {
	constructor() {
		super();

		this.state = {
			currentState: 'init', // changes to 'download', 'readyToShow', 'someError'
			inputData: [], // массив для хранения входных данных, передачи в Subject.js
			errorMessage: '',
			email: email,
			startDate: startDate,
			endDate: endDate
		};

		// Определение функций компонента для доступа к this
		this.fetchUser = this.fetchUser.bind(this);
		this.changeData = this.changeData.bind(this);
	}

	fetchUser(
		email = this.state.email,
		beginDate = this.state.startDate,
		endDate = this.state.endDate,
		needDateFormate = true
	) {
		/**
		 * Запрос на сервер
		 * needDateFormate  // флаг для форматирования данных '11.11.2222' => '2222-11-11'
		 */
		this.setState({currentState: 'download'});
		if (needDateFormate) {
			beginDate = beginDate.split('.').reverse().join('-');
			endDate = endDate.split('.').reverse().join('-');
		}
		let options = {
			data: {
				beginDate: beginDate,
				endDate: endDate,
				email: email
			},
			callback: (data) => {
				if (data.records.error === 'zero-id-request' || data.records.length === 0) {
					this.setState({
						currentState: 'error',
						errorMessage: 'Нет данных'
					});
					return
				}
				if (data.records.error === 'period-is-too-big') {
					this.setState({
						currentState: 'error',
						errorMessage: 'Слишком большой интервал'
					});
					return
				}
				this.setState({
					inputData: data.records,
					currentState: 'readyToShow'
				});
			},
			error: (str) => {
				this.setState({
					currentState: 'error',
					errorMessage: str
				});
			}
		};
		getNVR(options);
	}

	formDataFormat(array) {
		/**
		 * формирование из запрошенных файлов массива для дальнейшей обработки
		 * возвращается ans[
		 *  {
		 *   discipline // название дисциплины
		 *   items[]     // массив объектов, включающих в себя информацию по ответам
		 *  },
		 *  ...         // и следующая дисциплина
		 *  ]
		 */
		let ans = [];
		for (let i = 0; i < array.length; i++) {
			/**
			 * для каждого элемента входного массива проводим проверку, есть ли уже такая дисциплина в массиве-ответе
			 */
			let index = getIndexOfDiscipline(ans, array[i].discipline);
			if (index === -1) {
				/**
				 * если такой дисциплины еще нет, добавляем её в конец
				 */
				ans.push({
					discipline: array[i].discipline,
					items: [
						{
							date: array[i].date,
							beginLesson: array[i].beginLesson,
							recordingUrl: array[i].recordingUrl,
							lecturer: array[i].lecturer,
							kindOfWork: array[i].kindOfWork
						}
					]
				});
			} else {
				/**
				 * если есть дисциплина, дополняем массив объектов этой дисциплины новым значением
				 */
				ans[index].items.push({
					date: array[i].date,
					beginLesson: array[i].beginLesson,
					recordingUrl: array[i].recordingUrl,
					lecturer: array[i].lecturer,
					kindOfWork: array[i].kindOfWork
				});
			}
		}
		return ans; // возвращаем готовый массив объектов

		function getIndexOfDiscipline(array, discipline) {
			/**
			 * Поиск индекса элемента в массиве объектов
			 */
			for (let i = 0; i < array.length; i++) {
				if (array[i].discipline === discipline) {
					return i; // возвращаем индекс, если такая дисциплина уже есть в массиве
				}
			}
			return -1; // возвращаем -1, если дисциплины нет в массиве
		}
	}

	changeData(start, end) {
		/**
		 * Изменение даты в поиске
		 */
		let begin_format = start.format('YYYY-MM-DD');
		let end_format = end.format('YYYY-MM-DD');
		this.setState({
			startDate: begin_format,
			endDate: end_format
		});
		this.fetchUser(this.state.email, begin_format, end_format, false);
	}

	componentDidMount() {
		this.fetchUser();
	}

	render() {
		return (
			<div
				className={
					'nvr__container' +
					(this.state.currentState === 'download'
						? ' nvr__container_download'
						: '') +
					(this.state.currentState === 'error' ? ' nvr__container_error' : '')
				}
			>
				<div>
					<DateRangePicker
						initialSettings={{
							startDate: this.state.startDate,
							endDate: this.state.endDate,
							locale: {format: 'DD.MM.YYYY'}
						}}
						onCallback={this.changeData}
					>
						<input id={'jitsu_date-picker'} type="text" className="form-control"/>
					</DateRangePicker>
				</div>
				<div className="container_new">
					<div className="cap__container">
						<div className="cap">
							<span className="cap_text">Записи занятий </span>

						</div>
					</div>
					<div className="container my-5">
						<div className="header">
							<div className="row">
								<div className="col-md-5 col-lg-4 col-xl-3 d-flex align-items-center">
								</div>
							</div>
							<div class="noData">
								<div class="noData_inner"> Нет данных</div>
							</div>
						</div>
					</div>
					{/* <div className="container nvr__container__content">
          <div className="content">
            {this.state.inputData.map((el, i) => {
              return <Subject key={i} data={el} />;
            })}
          </div>
        </div>  */}


					<div className="nvr__plug nvr__plug-download">
						<h2>Подождите, информация загружается...</h2>
					</div>
					<div className="nvr__plug nvr__plug-error">
						<h2>{this.state.errorMessage}</h2>
					</div>
				</div>
			</div>
		);
	}
}

export default NVR;
