import React, {useEffect, useState} from 'react'
import './App.css';
import Elements from './views/stats/Elements'
import SearchPage from './views/search/SearchPage'
import ElementsGeneralStats from './views/general-stats/ElementsGeneralStats'
import ElementsAnalytics from './views/analytics/ElementsAnalytics'
import NVR from './views/nvr/NVR'
import {Switch, Route} from 'react-router-dom';
import {updateActualUser} from './utils/updateActualUser';
import {AuthContext} from './context/AuthContext';

function App() {
	const [data, setData] = useState({
		value: '',
		email: '',
		slug: ''
	})
	useEffect(() => {
		updateActualUser().then(res => {
			if (res) {
				setData(res)
			}
		})
	}, [])

	return (
		<main className="main">
			<AuthContext.Provider value={data}>
				<Switch>
					<Route path="/general">
						<ElementsGeneralStats/>
					</Route>
					<Route path="/analytics">
						<ElementsAnalytics/>
					</Route>
					<Route path="/nvr">
						<NVR/>
					</Route>
					<Route path="/">
						{
							(!window.location.search.includes('slug=') || window.location.search.endsWith('slug=')) ?
								// "portfolio.miem.hse.ru/","portfolio.miem.hse.ru/?slug="
								<div className="App">
									<SearchPage/>
								</div>
								:
								// "portfolio.miem.hse.ru/?slug=mashulgin", "portfolio.miem.hse.ru/?slug=123", ...
								<div className="App">
									<Elements/>
								</div>
						}
					</Route>
				</Switch>
			</AuthContext.Provider>
		</main>
	);
}

export default App;
