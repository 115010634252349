import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ChooseSelect.css';

import img from './AnaliticsImg.png';
import avatarImg from './avatar.png';
import fetchHelpers from '../../services/api/fetchHelpers';
import fetchUserAvatar from '../../services/api/FetchUserAvatar';
import {OverlayTrigger} from 'react-bootstrap';
import fetchUserRating from '../../services/api/FetchUserRating';
import {AuthContext} from '../../context/AuthContext';
import {promisedFetchHelper} from '../../utils/updateActualUser';

class Personal extends React.Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			value: '',  // значение поля ввода
			slug: '',   // уникальное имя пользователя почты в домене miem.hse.ru
			timer: undefined,   // функция-таймер для отправки запроса предложения пользователей
			users: undefined,   // массив для отображения предложений пользователей
			isMessageShow: false,   // отображение ошибки на странице
			isFinderShow: true,     // отображение предложений пользователей
			ratings: undefined,
			projectRating: 0
		};

		// Регистрация функций класса, определение для них this
		this.handleChange = this.handleChange.bind(this);
		this.findStats = this.findStats.bind(this);
		this.clickItem = this.clickItem.bind(this);
		this.timerSetter = this.timerSetter.bind(this);
		this.hideFinder = this.hideFinder.bind(this);
	}

	handleChange(event) {
		// Ввод текста в поле ввода
		this.setState({value: event.target.value, slug: '', isFinderShow: true}); // Перезапись состояния компонента
		setTimeout(() => {
			// Добавление условия в стек вызовов
			if (this.state.value && this.state.value.trim() !== '') {
				this.timerSetter(); // установка таймера если строка непустая
			} else {
				this.setState({users: undefined}); // иначе сброс предложения пользователей
			}
		}, 10)
	}

	timerSetter() {
		// Установка таймера
		clearTimeout(this.state.timer); // перед этим таймер сбрасываем
		this.setState({
			timer: setTimeout(() => {
				fetchHelpers({
						// Запрос пользователей с сервера
						str: this.state.value,
						callback: data => {
							if (('error' in data['users']) || (data['users'].length === 0)) {
								this.setState({
									users: undefined,
									isMessageShow: false,
									slug: ''
								});
								return
							}
							let data_users = []
							for (let value of Object.values(data['users'])) {
								data_users.push({'name': value['name'], 'email': value['email_hse'], 'group': value['info']});
							}
							this.setState({
								users: data_users,
								isMessageShow: false,
								slug: ''
							});
						},
						wasIncorrect: data => {
							this.setState({
								users: undefined,
								isMessageShow: true,
								slug: ''
							});
						}
					}
				)
			}, 500)
		});
	}

	clickItem(item) {
		// Выбор пользователя из предложенных
		let temp = {
			// объект для записи в состояния
			value: item.name,
			users: undefined,
			slug: item.email.split('@')[0]
		};
		this.setState(temp);
		localStorage.setItem('portfolio__search_init', JSON.stringify(temp)); // также записываем это состояние в localStorage
	}

	findStats(e, state) {
		// Обработчик перенаправления пользователя
		e.preventDefault(); // отменяем стандартное поведение формы - submit
		if (this.state.value !== '') {
			// при непустой строке ввода
			if (this.state.slug === '') {
				// если в slug нет данных, запрашиваем данные с сервера
				fetchHelpers({
					str: this.state.value,
					callback: data => {
						if (Object.keys(data).length === 1) {
							switch (state) {
								case 'stats':
									if (e.ctrlKey) {
										window.open(
											'?slug=' + data['users'][0]['email_hse'].split('@')[0],
											'_blank'
										);
									} else {
										window.location =
											'?slug=' + data['users'][0]['email_hse'].split('@')[0];
									}
									return
								case 'analytics':
									if (e.ctrlKey) {
										window.open(
											'analytics/?slug=' + data['users'][0]['email_hse'].split('@')[0],
											'_blank'
										);
									} else {
										window.location = 'analytics/?slug=' + data['users'][0]['email_hse'].split('@')[0];
									}
									return
								case 'nvr':
									if (e.ctrlKey) {
										window.open(
											'nvr/?slug=' + data['users'][0]['email_hse'].split('@')[0],
											'_blank'
										);
									} else {
										window.location = 'nvr/?slug=' + data['users'][0]['email_hse'].split('@')[0];
									}

									return
								default:
									if (e.ctrlKey) {
										window.open(
											'?slug=' + data['users'][0]['email_hse'].split('@')[0],
											'_blank'
										);
									} else {
										window.location = '?slug=' + data['users'][0]['email_hse'].split('@')[0];
									}
									return
							}
						}
					}
				})
			} else {
				// иначе перенаправляем пользователя по нужному пути
				switch (state) {
					case 'stats':
						if (e.ctrlKey) {
							window.open('?slug=' + this.state.slug, '_blank')
						} else {
							window.location = '?slug=' + this.state.slug;
						}
						return
					case 'analytics':
						if (e.ctrlKey) {
							window.open('analytics/?slug=' + this.state.slug, '_blank')
						} else {
							window.location = 'analytics/?slug=' + this.state.slug;
						}
						return
					case 'nvr':
						if (e.ctrlKey) {
							window.open('nvr/?slug=' + this.state.slug, '_blank')
						} else {
							window.location = 'nvr/?slug=' + this.state.slug;
						}
						return
					default:
						if (e.ctrlKey) {
							window.open('?slug=' + this.state.slug, '_blank')
						} else {
							window.location = '?slug=' + this.state.slug;
						}
						return
				}
			}
		}
	}

	hideFinder() {
		// Скрыть предложение пользователей
		this.setState({isFinderShow: false});
	}

	componentDidMount() {
		document.addEventListener('keydown', (e) => {
			// Добавление обработчика нажатия кнопки
			if (e.keyCode === 27 || e.code === 27) {
				// Если нажата кнопка esc - скрываем предложения пользователей
				this.hideFinder();
			}
		})
		let memory_init = localStorage.getItem('portfolio__search_init'); // получение предыдущих данных из lovalStorage
		if (memory_init) {
			// Если в lovalStorage были данные, переписываем состояния компонента
			this.setState(JSON.parse(memory_init));
		}

		const params = new URLSearchParams(window.location.search);
		const slug = params.get('slug')

		if (slug) {
			fetchUserAvatar(slug).then(res => {
				this.setState({
					img: res[0]
				})
			})

			fetchUserRating(slug).then(res => {
				const ratings = {}
				let projectRating = 0

				for (const ratingItem of res) {
					const oldNameChars = ratingItem.source.toLowerCase().split('')
					const firstChar = [...oldNameChars][0].toUpperCase()
					oldNameChars.splice(0, 1)
					const name = [firstChar, ...oldNameChars].join('')
					ratings[name] = ratingItem.project_rating
					projectRating += ratingItem.project_rating
				}

				projectRating /= res.length

				this.setState({
					ratings: ratings,
					projectRating: projectRating
				})
			})
		}
	}

	render() {
		return (
			<div className="container-person__wrap">
				<div className="container-person" eventKey="competence_pill">
					<div className="containe-item">
						<div className="personal-name">
							<img src={this.state.img || avatarImg} alt="logo" className="pesnon-photo"/>
							<div className="personal-info">
								<span className="text">{this.props.value}</span>
								{
									this.state.projectRating ?
										<span className="persona-rating">
									Проектный рейтинг: {this.state.projectRating.toFixed(2)}
											<OverlayTrigger key={'left'}
																			placement={'auto'}
																			popperConfig={{
																				modifiers: [
																					{
																						name: 'offset',
																						options: {
																							offset: [-20, 10]
																						}
																					},
																					{
																						name: 'flip',
																						options: {
																							allowedAutoPlacements: ['right', 'left']
																						}
																					}
																				]
																			}}
																			overlay={
																				<div className="question__overlay">
																					{
																						this.state.ratings ?
																							Object.keys(this.state.ratings).map(key => (
																								<p key={key}>{key}: {this.state.ratings[key].toFixed(2)}</p>
																							))
																							: undefined
																					}
																				</div>}
											>
                <span className="question-btn">
                    ?
                </span>
											</OverlayTrigger>
								</span> :
										undefined
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Personal