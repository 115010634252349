import React from 'react'
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';

class DatatableJitsiPage extends React.Component{

    render () {
        if (Object.keys(this.props.data_table).length === 0){
            return (
                <div></div>
            );
        }
        return (
            <div>
                <div id="table_top">
                    <MDBDataTable striped hover data={this.props.data_table}/>
                </div>
            </div>
        );
    }
}

export default DatatableJitsiPage;
