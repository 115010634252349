const requestURL_general_jitsi = '/api/formatters/jitsi.getTop'

function sendRequest(method, url, body = null){
    const headers ={
        'Content-Type': 'application/json'
    }
    return fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: headers

    }).then(response => {
        if (response.ok){
            return response.json()
        }
        return response.json().then(error =>{
            const e = new Error('Что то пошло не так')
            e.data = error
            throw e
        })
    })
}

function getTop_jitsi(year, month, callback){
        if (month !== "null") {
            sendRequest('POST', requestURL_general_jitsi, {
                year: year,
                month: month
            }).then(data_general_jitsi => {
                callback(data_general_jitsi);
            }).catch(err => callback("error"))
        } else {
            sendRequest('POST', requestURL_general_jitsi, {
                year: year
            }).then(data_general_jitsi => {
                callback(data_general_jitsi);
            }).catch(err => callback("error"))
        }
    }
    
    export default getTop_jitsi