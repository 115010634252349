import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';

class DatePicker extends React.PureComponent {
	constructor() {
		super();
	}

	render() {
		return (
			<>
				<DateRangePicker initialSettings={{
					startDate: this.props.begin_date,
					endDate: this.props.end_date,
					locale: {format: 'MMMM D, YYYY'}
				}} onCallback={this.props.changeData}>
					<input type="text" id={this.props.id || undefined} className="form-control"/>
				</DateRangePicker>
			</>
		);
	}
}

export default DatePicker