import React from 'react'
import CoursesRows from './CoursesRows'

import './index.css'
import getReccomendations from '../../../services/api/FetchReccomendations'
import Spinner from 'react-bootstrap/Spinner';

var parameters = {}
for (var i in window.location.search.replace('?', '').split('&')) {
	parameters[
		window.location.search.replace('?', '').split('&')[i].split('=')[0]
		] = window.location.search.replace('?', '').split('&')[i].split('=')[1]
}
let slug = parameters['slug']

class Courses extends React.Component {
	constructor() {
		super()
		this.state = {courses: [], showSpinner: true}
	}

	componentDidMount() {
		//Получение данных от форматтера аналитики "Теги компетенций"
		getReccomendations(slug, (data) => {
			console.log(data)
			this.setState({showSpinner: false})
			if (data === undefined) {
				return
			}
			this.setState({courses: data})
		})
	}

	render() {
		return (
			<div className="container_new">
				<div className="cap__container_stats">
					<div className="cap">
						<span className="cap_text">Рекомендация курсов</span>

					</div>
				</div>

				{
					this.state.showSpinner ?
						<div className="spinner"><Spinner animation="border" variant="primary" className="spinner"/></div> :
						<div className="container-sm">
							<CoursesRows courses={this.state.courses}/>
						</div>
				}
			</div>
		)
	}
}

export default Courses
