import React, {memo, useContext, useEffect, useState} from 'react';
import Elements from '../../stats/Elements';
import ChartsJitsi from '../../stats/Charts/ChartsJitsi';
import ChartsZulip from '../../stats/Charts/ChartsZulip';
import moment from 'moment';
import {AuthContext} from '../../../context/AuthContext';
import {updateActualUser} from '../../../utils/updateActualUser';

var parameters = {}
for (var i in window.location.search.replace('?', '').split('&')) {
	parameters[window.location.search.replace('?', '').split('&')[i].split('=')[0]] = window.location.search.replace('?', '').split('&')[i].split('=')[1]
}

var begin_date = moment().subtract(1, 'years')
var end_date = moment()

if (parameters['begin_date']) {
	begin_date = moment(parameters['begin_date'])
}
if (parameters['end_date']) {
	end_date = moment(parameters['end_date'])
}

const StatsTab = memo(({toggleState}) => {
	const [dates, setDates] = useState({
		beginDate: begin_date.format('YYYY-MM-DD'),
		endDate: end_date.format('YYYY-MM-DD')
	})
	const {email} = useContext(AuthContext)

	const updateChartData = (beginDate, endDate) => {
		setDates((prevState) => ({
			...prevState,
			beginDate: beginDate,
			endDate: endDate
		}))
	}

	return (
		<div
			className={toggleState === 2 ? 'content  active-content' : 'content'}
		>
			<Elements updateChartData={updateChartData}/>
			{
				email ?
					<>
						<ChartsJitsi email={email} beginDate={dates.beginDate} endDate={dates.endDate}/>
						<ChartsZulip email={email} beginDate={dates.beginDate} endDate={dates.endDate}/>
					</> :
					undefined
			}
			{/*<ChartsGeneralJitsi></ChartsGeneralJitsi>*/}
			{/*<ChartsGeneralZulip></ChartsGeneralZulip>*/}
		</div>
	);
});

export default StatsTab;