import React, {useState} from 'react';
import './ChooseSelect.css';
import Sentiment from '../analytics/Sentiment/Sentiment';
import CompetenceTags from '../analytics/CompetenceTags/CompetenceTags';
import Courses from '../analytics/CourseRecommendations';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Choose.css';
import NVR from '../nvr/NVR';
import StatsTab from './tabs/StatsTab';
import ChooseTabs from './chooseTabs';

function Tabs() {
	const [toggleState, setToggleState] = useState(1);

	const toggleTab = (index) => {
		setToggleState(index);
	};

	return (
		<div>
			<ChooseTabs toggleState={toggleState} toggleTab={toggleTab}/>
			<div className="container__Choose__tab">
				<div className="content-tabs">
					<div
						className={toggleState === 1 ? 'content  active-content' : 'content'}
					>
						<Sentiment/>
						<CompetenceTags/>
						<Courses/>
					</div>

					<StatsTab toggleState={toggleState}/>
					<div
						className={toggleState === 3 ? 'content  active-content' : 'content'}
					>
						<NVR></NVR>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Tabs;
