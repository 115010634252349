import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Choose.css';

import img from './AnaliticsImg.png';
import imgb from './BasicImg.png';
import imgh from './HomeIcon.png';

class Header extends React.Component {

	render() {
		return (
			<header className="header">
				<div className="header__nav">
					<ul className="item">
						<a href="https://miem.hse.ru"><img src={imgb} alt="logo" className="favicon"/></a>
						<a href="/"> <img src={imgh} alt="logo" className="home"/></a>
					</ul>
				</div>
			</header>

		);
	}
}

export default Header